<template>
  <validation-observer ref="passwordcheck">
    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <!-- old password -->
        <b-col cols="12" xl="8" md="8">
          <b-form-group
            :label="$t('Old Password')"
          >
            <validation-provider #default="{ errors }" rules="required" name="oldPassword">
              <b-input-group 
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null">
                <b-form-input
                  id="oldPassword"
                  :state="errors.length > 0 ? false:null"
                  v-model="oldPassword"
                  :type="passwordFieldTypeOld"
                  :placeholder="$t('Old Password')"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIconOld"
                    class="cursor-pointer"
                    @click="togglePasswordOld"
                  /></b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!--/ old password -->
      </b-row>
      <b-row>
        <!-- new password -->
        <b-col cols="12" xl="8" md="8">
          <b-form-group
            :label="$t('New Password')"
          >
            <validation-provider #default="{ errors }" rules="confirmed:confirmation|required" name="NewPassword">
              <b-input-group 
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null">
                  <b-form-input
                    id="newPassword"
                    :state="errors.length > 0 ? false:null"
                    v-model="newPassword"
                    class="form-control-merge"
                    :type="passwordFieldTypeNew"
                    :placeholder="$t('New Password')"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!--/ new password -->
      <!-- retype password -->
        <b-col cols="12" xl="8" md="8">
           <validation-provider #default="{ errors }" vid="confirmation" name="Retype Password">
            <b-form-group
              :label="$t('Retype Password')"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  v-model="repeatNewPassword"
                  :type="passwordFieldTypeRetype"
                  :placeholder="$t('New Password')"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIconRetype"
                    class="cursor-pointer"
                    @click="togglePasswordRetype"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <!--/ retype password -->
        <!-- buttons -->
        <b-col cols="12">
          <div class="d-flex align-items-center justify-content-end">
            <button class="lab-btn lab-btn-info btn-save" 
              type="submit" @click.prevent="submit">
              <b-spinner
                v-if="$store.getters.getLoader"
                class="mr-1"
                small
                variant="light"
              />
              Save Settings
            </button>
          </div>
        </b-col>
        <!--/ buttons -->
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
// Alert
import AlertService from '@/common/alert.service';

// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue';
import Ripple from 'vue-ripple-directive';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, confirmed } from '@validations';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
   },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      confirmed,
      oldPassword: '',
      newPassword: '',
      repeatNewPassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },

    submit() {
      this.$refs.passwordcheck.validate().then(success => {
        if (success) {
          const query = { oldPassword: this.oldPassword, newPassword: this.newPassword, repeatNewPassword: this.repeatNewPassword };
          this.$store.dispatch('profileSettings/savePassword', query)
          .then(response => {
            if (response.statusCode === 200) {
              this.$toast({
                component: ToastificationSuccess,
                position: 'top-right',
                props: { text: response.message },
              })
            } else {
              AlertService.error(this, response.message)
            }
          });
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .input-group {
    box-shadow: none !important;
  }
  .form-control:focus {
    border: 2px solid var(--lab-minted-elegance-color) !important;
  }
  .is-invalid .form-control:focus {
    border: 2px solid red !important;
  }
</style>
