<template>
  <div>
    <validation-observer ref="userFields">
      <b-form class="mt-2">
        <b-row>
          <b-col cols="12" xl="6" md="6" class="mb-2">
            <b-form-group
              :label="$t('Name')"
            >
              <validation-provider #default="{ errors }" rules="required" name="firstName">
                <b-form-input
                  id="firstName"
                  :state="errors.length > 0 ? false:null"
                  v-model="profileData.firstName"
                  :placeholder="$t('First Name')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" xl="6" md="6" class="mb-2">
            <b-form-group
              :label="$t('Surname')"
            >
              <validation-provider #default="{ errors }" rules="required" name="Surname">
                <b-form-input
                  id="Surname"
                  :state="errors.length > 0 ? false:null"
                  v-model="profileData.lastName"
                  :placeholder="$t('Surname')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" xl="6" md="6">
            <b-form-group
              :label="$t('Email')"
            >
              <validation-provider #default="{ errors }" rules="email" name="email">
                <b-form-input
                  id="email"
                  type="email"
                  :state="errors.length > 0 ? false:null"
                  v-model="profileData.email"
                  :placeholder="$t('Email')"
                  @input="checkUserEmail(true)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small class="text-danger" v-show="!isValidUserEmail"> Can't get this email</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          
          <b-col cols="12" xl="6" md="6" class="mb-2">
            <b-form-group
              :label="$t('CID')"
            >
              <b-form-input
                id="cid" readonly
                v-model="profileData.userName"
                :placeholder="$t('CID')"
                @input="checkUserName(true)"
              />
            </b-form-group>
          </b-col>
          
          <b-col cols="12" xl="8" md="8" v-if="profileData.isEmailVerified === false">
            <b-alert variant="info" show>
              <div class="alert-body">
                <span>
                  To ensure the security of your account and activate your membership, please verify your email address. 
                  <a class="text-warning" @click="sendVerifyMail()">Send verification link</a>
                  </span>
              </div>
            </b-alert>
          </b-col>

          <b-col cols="12" xl="8" md="8" class="mt-2">
            <h4>Account Deletion</h4>
            <div class="p-1">
              <button type="button" class="lab-btn lab-btn-outline-mellow" @click="removeAccount();">Delete My Account</button>
            </div>
          </b-col>

          <b-col cols="12">
            <div class="d-flex align-items-center justify-content-end">
              <button class="lab-btn lab-btn-info btn-save" 
                type="submit" @click.prevent="submit">
                <b-spinner
                  v-if="$store.getters.getLoader"
                  class="mr-1"
                  small
                  variant="light"
                />
                Save Settings
              </button>
            </div>
          </b-col>
        </b-row>
        
      </b-form>
    </validation-observer>

    <delete-account-modal :modalObject="deleteObject" v-if="deleteObject.showModal" />
  </div>
</template>

<script>
import AlertService from '@/common/alert.service'
import ApiService from '@/common/api.service'

// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations';

import { formatDateToDMY } from '@core/utils/filter'

import DeleteAccountModal from './DeleteAccountModal.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    DeleteAccountModal,
  },
  data() {
    return {
      required,
      email,
      isValidUserName: true,
      isValidUserEmail: true,
      avatarText: '',
      adSoyad: '',
      options: {    
        phone: {
          blocks: [0, 3, 0, 3, 4],
          delimiter: ' ',
          delimiters: ['(', ')', ' ', '-', '-'],    
          numericOnly: true,
          uppercase: false,
          lowercase: false,
        },
      },
      profileData: {},
      deleteObject: {
        item: null,
        showModal: false,
        onSuccess: this.fetchKits,
      },
    }
  },
  methods: {
    formatDateToDMY,
    
    removeAccount() {
      this.deleteObject.showModal = true;
    },

    submit() {
       this.$refs.userFields.validate().then(success => {
        if (success) {
          this.$store.dispatch('profileSettings/saveGeneralUserInfo', this.profileData)
            .then(response => {
              if (response.statusCode === 200) {
                this.$toast({
                  component: ToastificationSuccess,
                  position: 'top-right',
                  props: { text: response.message },
                })
              } else {
                AlertService.warning(this, response.message)
              }
            });
        }
       })
    },

    uploadPicture() {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = 'image/png, image/jpeg'
      fileInput.addEventListener('change', event => {
        this.pictureForm.set('file', event.target.files[0]);

        ApiService.upload('user/profilepicture/save', this.pictureForm, this)
          .then(response => {
            if (response.statusCode === 200) {
              this.profileData.pictureUrl = response.result;
            } else {
              AlertService.error(this, response.message)
            }
          });
      })
      fileInput.click();
    },

    checkUserName() {
      if (this.profileData.userName) {
        this.$store.dispatch('profileSettings/checkUserName', { userName: this.profileData.userName })
        .then(response => {
          if (response.statusCode === 200) {
            this.isValidUserName = true;
          } else if (response.statusCode === 1021) {
            this.isValidUserName = false;
          } else {
            //
          }
        });
      }
    },

    checkUserEmail() {
      if (this.profileData.email) {
        this.$store.dispatch('profileSettings/checkUserEmail', { email: this.profileData.email })
        .then(response => {
          if (response.statusCode === 200) {
            this.isValidUserEmail = true;
          } else if (response.statusCode === 1021) {
            this.isValidUserEmail = false;
          } else {
            //
          }
        });
      }
    },

    sendVerifyMail() {
      ApiService.get(`UserVerification/send-email-verify-code/${this.profileData.email}`, {}, this)
        .then(response => {
          if (response.statusCode === 200) {
            AlertService.success(this, 'The verification link has been sent to your email address.');
          } else {
            AlertService.warning(this, response.message);
          }
        });
    },
  },
  beforeCreate() {
    this.$store.dispatch('profileSettings/fetchGeneralSettings')
      .then(response => {
        if (response.statusCode === 200) {
          this.profileData = response.result;
        }
      })
  },
}
</script>

<style lang="scss" scoped>

</style>
