<template>
  <div class="profile-settings">
    <div class="dashboard-card settings-card">
      <h4>Settings</h4>

      <div class="settings-tabs">
        <div class="setting-tab-item mr-1" :class="{'selected' : selectedTab === 'GENERAL' }" @click="changeTab('GENERAL')">
          <h5>General</h5>
        </div>
        <div class="setting-tab-item ml-1" :class="{'selected' : selectedTab === 'PASSWORD' }" @click="changeTab('PASSWORD')">
          <h5>Change Password</h5>
        </div>
      </div>

      <profile-setting-general v-if="selectedTab === 'GENERAL'"/>
      <profile-setting-password v-if="selectedTab === 'PASSWORD'"/>
    </div>
  </div>
</template>

<script>
import ProfileSettingGeneral from './ProfileSettingGeneral.vue'
import ProfileSettingPassword from './ProfileSettingPassword.vue'

export default {
  components: {
    ProfileSettingGeneral,
    ProfileSettingPassword,
  },
  data() {
    return {
      selectedTab: 'GENERAL',
    }
  },
  methods: {
    changeTab(tab) {
      this.selectedTab = tab;
    },
  },
  beforeCreate() {
  },
}
</script>

<style lang="scss" scoped>
.profile-settings  {
  margin: 0 auto;
  max-width: 1100px !important;
}
.settings-card {
  padding: 1.5rem 2rem !important;

  .btn-save {
    padding: 1rem !important;
    font-size: 1rem;
  }
}

.settings-tabs  {
  display: flex;
  align-items: center;
  justify-content: center;

  .setting-tab-item {
    cursor: pointer;
    padding: 0.1rem 1rem;
    h5 {
      color: var(--lab-carbon-fiber);
      font-size: 1.1rem;
    }
  }
  .setting-tab-item.selected {
    border-bottom: 4px solid var(--lab-minted-elegance-color);
  }
}

.dark-layout {
  .settings-tabs {
    .setting-tab-item h5 {
      color: var(--lab-white);
    }
  }
}
</style>
