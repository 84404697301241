<template>
  <!-- select 2 demo -->
  <div>
    <b-modal
      ref="modal-confirm-password"
      scrollable
      no-close-on-backdrop
      cancel-variant="outline-secondary"
      hide-footer
      hide-header
      size="sm"
    >
      <div>
        <div class="title mt-2">
          <h4>Password</h4>
        </div>
        <validation-observer ref="confirmPasswordFields">
          <b-form class="mt-2">
            <div class="mt-1">
              <label class="confirm-password-label">Enter your password below to progress</label>
              <validation-provider #default="{ errors }" rules="required" name="password">
                <b-form-input
                  id="password" type="password"
                  :state="errors.length > 0 ? false:null"
                  v-model="modalObject.item.password"
                  :placeholder="$t('password')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="text-right mt-2">
              <button class="lab-btn lab-btn-lg mr-1 lab-btn-light" @click="hiddenModal()"> Cancel </button>
              <button class="lab-btn lab-btn-lg lab-btn-mellow-melon" type="submit" @click.prevent="handleOk"> 
                <spinner-loader /> Delete 
              </button>
            </div>
          </b-form>
        </validation-observer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    modalObject: {
      type: Object,
    },
  },
  data() {
    return {
      required,
    }
  },
  methods: {
    initialModal() {
      this.$refs['modal-confirm-password'].show();
    },
    handleOk() {
      this.$refs.confirmPasswordFields.validate().then(success => {
        if (success) {
          this.modalObject.onSuccess(response => {
            if (response) {
              this.hiddenModal();
            }
          });
        }
       });
    },
    hiddenModal() {
      this.$refs['modal-confirm-password'].hide()
      this.modalObject.showModal = false;
    },
  },
  mounted() {
    this.initialModal()
  },
};
</script>

<style lang="scss" scoped>
.confirm-password-label {
  font-size: 0.8rem;
  font-weight: 500;
  margin-bottom: 1rem;
  color: var(--lab-stieglitz-silver) !important;
}

.dark-layout .confirm-password-label {
  color: var(--lab-white) !important;
}
</style>
