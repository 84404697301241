<template>
  <svg :id="$store.state.appConfig.layout.skin" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43.8 37.47">
    <defs>
    </defs>
    <g id="Layer_2-2" data-name="Layer 2">
      <path class="cls-1" d="M42.67,32.02L24.62,1.13c-1.5-1.5-3.95-1.5-5.45,0L1.13,32.02c-1.5,1.5-1.5,3.94,0,5.45H42.67c1.51-1.51,1.51-3.95,0-5.45h0ZM20.24,12.22c0-1.19,.97-2.16,2.16-2.16s2.16,.97,2.16,2.16v10.09c0,1.19-.97,2.16-2.16,2.16s-2.16-.97-2.16-2.16V12.22h0Zm2.17,19.47c-1.19,0-2.16-.97-2.16-2.16s.97-2.16,2.16-2.16,2.16,.97,2.16,2.16-.97,2.16-2.16,2.16Z"/>
    </g>
  </svg>
</template>

<script>
export default {
  components: {
  },
  props: {
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
svg {
  height: 18px;
}

#light .cls-1 {
  fill: #2d2d2d;
}
#dark .cls-1 {
  fill: #EF2660;
}
</style>
